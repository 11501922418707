import React from "react";
import { Block, BlockTitle, Icon, PreviewCard, Row } from "../../components/Component";
import { Button, Card, Col } from "reactstrap";
import DjSectionImg from "../../images/DJ_Section3.jpg";
import { useNavigate } from "react-router";

const ClientSection = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Block className="border border-1">
        <PreviewCard className="mx-1 my-1 border-0">
          <Row>
            <Col lg="8" className="">
              <BlockTitle className="fs-2 lead-text mb-3">
                Find the Perfect DJ with {process.env.REACT_APP_TITLE}{" "}
              </BlockTitle>
              <p>
                Discover and connect with top DJs for your events with {process.env.REACT_APP_TITLE} , the leading
                platform for booking professional DJs. Easily find and book DJs, manage your event details, and ensure a
                seamless experience—all in one place.
              </p>
              <div className="border-1 border-primary leaflet-bar m-3 p-3 shadow">
                <div>
                  <span className="fs-3 lead-text">Why Choose {process.env.REACT_APP_TITLE} ?</span>
                </div>
                <div className="row justify-between px-sm-1 px-0">
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Easy-to-Use Platform</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Better DJ Connections</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Simple Event Scheduling</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Manage Your Bookings Effortlessly</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Automated Booking Confirmations</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Secure and Quick Payments</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">DJ Profiles with Tools and Features</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Safe and Secure Transactions</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">Access Anywhere with Mobile</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-end px-2 my-1">
                <Button className="text-white bg-primary" color="primary" onClick={() => navigate("/client-section")}>
                  Discover More
                </Button>
              </div>
            </Col>
            <Col lg="4">
              <div className="justify-center h-100 mt-3 mt-lg-0">
                <img src={DjSectionImg} alt="DJ Section" className="img-fluid object-fit-cover h-375px" />
              </div>
            </Col>
          </Row>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ClientSection;
