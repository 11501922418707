import React from "react";
import { useNavigate } from "react-router";
import { Icon } from "../../../components/Component";
import { Alert, Button, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { getAllServices } from "../../../http/get/getApi";
import placeHolderImg from "../../../images/No Result.png";
import { ShimmerBadge, ShimmerButton, ShimmerText, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";

const ServiceCards = () => {
  const navigate = useNavigate();

  const {
    data: serviceList,
    isLoading: serviceListLoading,
    isError: serviceListError,
  } = useQuery({
    queryKey: ["get-service-list"],
    queryFn: () => getAllServices(),
  });
  
  return (
    <>
      <div class="container my-5">
        <div class="text-center mb-3">
          <div class="fs-4">Explore Our Services</div>
          <p className="mb-0">
            Scroll down to discover the full range of services we offer, each designed to elevate your event and create
            unforgettable memories. From our core DJ services to unique offerings like Cold Sparks and Glow Tube
            Lighting, we provide everything you need to make your event truly spectacular.
          </p>
          <div class="fs-2 fw-medium text-primary mt-2">Transform Your Event with Our Versatile DJ Services</div>
        </div>
        <div class="row g-4 py-2 overflow-auto mt-1" style={{ height: "600px", scrollbarWidth: "none" }}>
          {serviceListLoading ? (
            <Row>
              <Col lg={4}>
                <ShimmerBadge width={150} />
                <ShimmerText />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
            </Row>
          ) : serviceListError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
            </div>
          ) : (
            <>
              {serviceList?.status === false ? (
                <Alert color="white" className="text-center">
                  <h4 className="alert-heading">Oops! Something went wrong.</h4>
                  <p>
                    We're sorry, but we couldn't get the Services for you at the moment. Please try again later or click
                    the button below to refresh the page and try again.
                  </p>
                  <Button color="primary" onClick={() => window.location.reload()}>
                    Refresh
                  </Button>
                </Alert>
              ) : serviceList?.data?.Services.length < 0 ? (
                <div className="d-flex justify-center align-center h-100px fs-5">No Services Found</div>
              ) : (
                serviceList?.data?.Services.map((item, idx) => (
                  <div class="col-auto col-sm-6 col-lg-4 my-3 justify-center" key={idx}>
                    <div class="cardUI px-4 py-3 pb-2">
                      <div class="card-detailsUI">
                        <div class="d-flex align-center mb-2 text-titleUI">
                          <div class="fs-1 align-center">
                            <Icon name="play-circle"></Icon>
                          </div>
                          <div class="fs-16px mx-1 fw-semibold">{item?.title}</div>
                        </div>

                        <div>{item?.description}</div>
                      </div>
                      <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                        More info
                      </button>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
