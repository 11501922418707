import React, { createContext, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "../../images/header_logo.png";
import { useTheme } from "../provider/Theme";
import Cookies from "js-cookie";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import { createcontext } from "../Index";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
    "bg-white": true,
    "text-dark": true,
  });

  function cookieVariable() {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }
  // const setLogout = () => {
  //   const now = new Date();
  //   const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //   Cookies.set("logoutURL", process.env.REACT_APP_PUBLIC_URL, {
  //     domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //     expires: expires,
  //   });
  //   const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
  //   window.location.href = logoutUrl;
  // };
  const authTokenChk = Cookies.get("authToken");

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const { heroSection, setHeroSection } = useContext(createcontext);
  useEffect(() => {
    setHeroSection(true);
  }, []);

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap d-flex">
          <div className="nk-header-app-name d-flex align-items-center" style={{ width: "150px" }}>
            <div className="nk-header-app-logo h-100 w-60">
              <Link to="/" className="nav-link text-dark w-100">
                <img src={Logo} alt="DJ Logo" className="my-1" />
              </Link>
            </div>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav d-flex list-unstyled mb-0">
              <li className="nav-item me-3 d-none d-md-block">
                <Link
                  to={`${process.env.REACT_APP_PUBLIC_URL}`}
                  className={currentUrl === "/" || currentUrl === "/home" ? "text-primary" : "infoSection"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to="about" className={currentUrl === "/about" ? "text-primary nav-link" : "infoSection"}>
                  About
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link
                  to="dj-section"
                  className={currentUrl === "/dj-section" ? "text-primary nav-link" : "infoSection"}
                >
                  DJ Only
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to={`https://blogs.ipartydjcrm.com/`} className="infoSection" target="_blank">
                  Blog
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to="contact" className={currentUrl === "/contact" ? "text-primary nav-link" : "infoSection"}>
                  Contact
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link
                  to="business-portal"
                  className={currentUrl === "/business-portal" ? "text-primary nav-link" : "infoSection"}
                >
                  Business Portal
                </Link>
              </li>
              {authTokenChk ? (
                <li className="nav-item">
                  <User />
                </li>
              ) : (
                <li className="nav-item d-none d-md-block">
                  <Link to={`${process.env.REACT_APP_ACCOUNT_URL}login`} className="nav-link" onClick={cookieVariable}>
                    <button className="btn btn-primary">Sign In</button>
                  </Link>
                </li>
              )}
              <li className="nav-item me-3 d-md-none">
                <AppDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
