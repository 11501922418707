import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { Block } from "../../../components/Component";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../../images/about_section.png";
import img2 from "../../../images/contact_us.jpg";
import img3 from "../../../images/contact_us2.jpg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../css/style.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};

const BusinessPortal = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [formActive, setFormActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [realIndex, setRealIndex] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handleContact = (value) => {
    setFormActive(value);
  };
  const renderForm = () => {
    return (
      <div className="h-100 w-100 m-2">
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <label htmlFor="event_message">
                    Full Name <Required />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    placeholder="Enter Full Name"
                    {...register("name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                    })}
                    maxLength={30}
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <label htmlFor="event_message">
                    Contact Number <Required />
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="phone_number"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    placeholder="Enter Contact Number"
                    {...register("phone_number", {
                      required: "This is required",
                      maxLength: {
                        value: 15,
                        message: "You rich maximum contact number limit!",
                      },
                      minLength: {
                        value: 9,
                        message: "contact number at least contains 9 digit!",
                      },
                    })}
                    minLength={9}
                    value={formData.phone_number}
                    onChange={(e) => {
                      if (e.target.value.length <= 15) {
                        setFormData({ ...formData, phone_number: e.target.value });
                      } else {
                        blockNumbers();
                      }
                    }}
                  />
                  {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-1">
                  <label htmlFor="event_message">
                    Email <Required />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "This is required",
                      pattern: {
                        value:
                          // eslint-disable-next-line
                          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Enter a valid email address",
                      },
                      maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                    })}
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-1">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control h-100px"
                    type="text"
                    id="message"
                    placeholder="Enter Event Message"
                    {...register("message", {
                      pattern: {
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 5,
                        message: "Please enter at least 5 characters",
                      },
                    })}
                    maxLength={500}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                  {errors.message && <span className="invalid">{errors.message.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-2">
                  {loading ? (
                    <Button disabled color="primary" className="justify-center w-100 rounded">
                      <Spinner size="sm" />
                      <span> Loading... </span>
                    </Button>
                  ) : (
                    <Button color="primary" className="justify-center w-100 rounded" type="submit">
                      Send Message
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  };
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
    reset({});
  };
  const handleSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Information sent successfully",
      text: "We will reach you soon!",
      focusConfirm: false,
    });
  };
  const FormSubmit = async (form) => {
    const { name, email, phone_number, message } = form;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contact", phone_number);
      formData.append("message", message);
      const contactdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      const contaactinfo = await contactdata.json();
      if (contaactinfo && contaactinfo.status) {
        const submittedData = contaactinfo?.data;
        console.log(submittedData);
        // toast.success("We will be reach soon!");
        resetForm();
        setFormActive("");
        handleSuccess();
      } else {
        toast.error(contaactinfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  const blockNumbers = () => {};
  return (
    <>
      <Content>
        <Block className="">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper2"
          >
            <SwiperSlide>
              <div style={{ position: "relative" }}>
                <img
                  src={img1}
                  className="opacity-75"
                  style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
                />
                <div
                  className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                  style={{ top: 50, right: -40, zIndex: 1 }}
                >
                  <div className="flex-column align-start justify-center w-60">
                    <div className="my-md-3 my-0">
                      <span className="fs-1 d-none d-md-block">Mobile Application Support</span>
                      <span className="fs-3 d-md-none">Mobile Application Support</span>
                    </div>
                    <div className="my-md-3 my-0">
                      <span className="fs-5 w-100 d-none d-md-block">
                        DJs from around the world bring diverse sounds and styles to the global music scene, blending
                        cultural influences with innovative beats. From underground clubs to massive festivals, these
                        artists redefine electronic music, uniting fans across borders. Their unique talents and
                        creative mixes continue to shape the evolution of modern music. Discover the rhythmic pulse of
                        the world's top DJs.
                      </span>
                      <span className="fs-6 w-100 d-md-none">
                        DJs from around the world bring their unique cultural influences to their performances, blending
                        global music styles with local favorites to create diverse and dynamic sets.
                      </span>
                    </div>
                    <div className="my-md-3 my-1">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => handleContact(1)}
                      >
                        Contact now!
                      </Button>
                    </div>
                  </div>
                  <div className="w-35">{formActive === 1 && renderForm()}</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ position: "relative" }}>
                <img
                  src={img2}
                  className="opacity-75"
                  style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
                />
                <div
                  className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                  style={{ top: 50, right: -40, zIndex: 1 }}
                >
                  <div className="flex-column align-start justify-center w-60">
                    <div className="my-md-3 my-0">
                      <span className="fs-1 d-none d-md-block">Mobile Application Support</span>
                      <span className="fs-3 d-md-none">Mobile Application Support</span>
                    </div>
                    <div className="my-md-3 my-0">
                      <span className="fs-5 w-100 d-none d-md-block">
                        DJs from around the world bring diverse sounds and styles to the global music scene, blending
                        cultural influences with innovative beats. From underground clubs to massive festivals, these
                        artists redefine electronic music, uniting fans across borders. Their unique talents and
                        creative mixes continue to shape the evolution of modern music. Discover the rhythmic pulse of
                        the world's top DJs.
                      </span>
                      <span className="fs-6 w-100 d-md-none">
                        DJs from around the world bring their unique cultural influences to their performances, blending
                        global music styles with local favorites to create diverse and dynamic sets.
                      </span>
                    </div>
                    <div className="my-md-3 my-1">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => handleContact(2)}
                      >
                        Contact now!
                      </Button>
                    </div>
                  </div>
                  <div className="w-35">{formActive === 2 && renderForm()}</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ position: "relative" }}>
                <img
                  src={img3}
                  className="opacity-75"
                  style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "auto" }}
                />
                <div
                  className="position-absolute align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white w-100"
                  style={{ top: 50, right: -40, zIndex: 1 }}
                >
                  <div className="flex-column align-start justify-center w-60">
                    <div className="my-md-3 my-0">
                      <span className="fs-1 d-none d-md-block">Mobile Application Support</span>
                      <span className="fs-3 d-md-none">Mobile Application Support</span>
                    </div>
                    <div className="my-md-3 my-0">
                      <span className="fs-5 w-100 d-none d-md-block">
                        DJs from around the world bring diverse sounds and styles to the global music scene, blending
                        cultural influences with innovative beats. From underground clubs to massive festivals, these
                        artists redefine electronic music, uniting fans across borders. Their unique talents and
                        creative mixes continue to shape the evolution of modern music. Discover the rhythmic pulse of
                        the world's top DJs.
                      </span>
                      <span className="fs-6 w-100 d-md-none">
                        DJs from around the world bring their unique cultural influences to their performances, blending
                        global music styles with local favorites to create diverse and dynamic sets.
                      </span>
                    </div>
                    <div className="my-md-3 my-1">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => handleContact(3)}
                      >
                        Contact now!
                      </Button>
                    </div>
                  </div>
                  <div className="w-35">{formActive === 3 && renderForm()}</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper2 swiperss"
          >
            {console.log("Current real index:", realIndex)} {/* Debugging */}
            <SwiperSlide className={`swiper-slidess`}>
              <img src={img1} />
            </SwiperSlide>
            <SwiperSlide className={`swiper-slidess`}>
              <img src={img2} />
            </SwiperSlide>
            <SwiperSlide className={`swiper-slidess`}>
              <img src={img3} />
            </SwiperSlide>
          </Swiper>
        </Block>
      </Content>
    </>
  );
};

export default BusinessPortal;
