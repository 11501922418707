import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, Button, DropdownMenu, Spinner } from "reactstrap";
import { json, useLocation, useNavigate } from "react-router";
import "./style.module.css";
import { getAllCategories, getAllCities, getAllCompanies } from "../../http/get/getApi";
import { useQuery } from "react-query";
import Cookies from "js-cookie";
import vidHM from "../../images/vidHM.mp4";
import { BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import FrontPageLogo from "../../images/IPartyDJ_MIX.png";
import { createcontext } from "../../layout/Index";
import { getCookie } from "../../utils/Utils";

const HeroSection = () => {
  const location = useLocation();
  const [input, setInput] = useState("");
  const [fitlerCategory, setFitlerCategory] = useState("");
  const [showEventTypeSelect, setShowEventTypeSelect] = useState(false);
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [city, setCity] = useState("");
  const [cityData, setCityData] = useState([]);
  const [citySelected, setCitySelected] = useState("");
  const navigate = useNavigate();
  const eventTypeDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState(false);
  const timeoutRef = useRef();
  const [lockScreen, setLockScreen] = useState(false);
  const [mainScreen, setMainScreen] = useState(false);

  const { heroSection, setHeroSection } = useContext(createcontext);

  let latLongCityData = getCookie("latLongCity");
  useEffect(() => {
    if (latLongCityData) {
      setCityData(latLongCityData);
    }
  }, []);

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useQuery({
    queryKey: ["get-category-list"],
    queryFn: () => getAllCategories(),
  });

  const {
    data: cityList,
    isLoading: cityListLoading,
    isError: cityListError,
  } = useQuery({
    queryKey: ["get-city-list", cityData],
    queryFn: () => getAllCities({ city: cityData }),
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventTypeDropdownRef.current && !eventTypeDropdownRef.current.contains(event.target)) {
        setShowEventTypeSelect(false);
      }
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setShowCitySelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = (inputType) => {
    if (inputType === "CategoryType") {
      setShowEventTypeSelect(true);
      setShowCitySelect(false);
    } else if (inputType === "city") {
      setShowCitySelect(true);
      setShowEventTypeSelect(false);
    }
  };

  const handleSelect = (name) => {
    setInput(name.title);
    setFitlerCategory(name.id);
    setShowEventTypeSelect(false);
  };

  const handleCitySelect = (name) => {
    console.log(name);
    const cityNameWithState = `${name?.city_name}, ${name?.state_name}`;
    setCitySelected(cityNameWithState);
    // setCity(cityNameWithState);
    setCity(name?.city_name);
    setShowCitySelect(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const fetchedCompaniesList = await getAllCompanies({
        type: 10,
        offset: 1,
        category: fitlerCategory,
        city: city,
      });
      setLoading(false);
      navigate("/dj-profiles/1", { state: { companiesList: fetchedCompaniesList, fitlerCategory, city } });
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("cityData", citySelected, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      Cookies.set("cityId", city, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      Cookies.remove("latLongCity");
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCitySelected(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCityData(value ? value : latLongCityData);
    }, 1000);
  };

  const handleHeroSection = () => {
    setHeroSection(true);
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("Herosection", "true", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };

  useEffect(() => {
    const heroCokkie = getCookie("Herosection");
    if (heroCokkie === "true") {
      setHeroSection(true);
      setMainScreen(true);
      setLockScreen(false);
    } else {
      setHeroSection(false);
      setMainScreen(false);
      setLockScreen(true);
    }
  }, [heroSection]);

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "90.5vh",
          position: "relative",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          <source src={vidHM} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {mainScreen === true && (
          <div className="text-center p-4 w-70" style={{ zIndex: 1 }}>
            <h1 className="mb-4 text-center font-weight-bold text-white">
              Your <span style={{ color: "#ff1300" }}>Ultimate</span> DJ Booking Platform
            </h1>
            <div className=" bg-white py-3 px-3 rounded-3">
              <p className="mb-2 fs-5">Search hundreds of DJs for weddings, birthday parties, and more.</p>
              <Form className="mb-3">
                <Row className="align-items-end gx-0">
                  <Col md={5}>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="xl"
                      type="text"
                      value={input}
                      className="rounded-start flex-grow-1 rounded-0 my-1"
                      placeholder="Find the perfect DJ for your event."
                      onClick={() => handleInputClick("CategoryType")}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setDropDownData(true);
                      }}
                    />
                    {showEventTypeSelect && (
                      <div
                        ref={eventTypeDropdownRef}
                        className="position-absolute h-200px overflow-auto heromanual-dropdown"
                        style={{ zIndex: 1, width: "27%" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border">
                          {categoryListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : categoryListError ? (
                            <div>Error loading category. Please try again.</div>
                          ) : categoryList?.data?.Category?.length ? (
                            categoryList?.data?.Category?.map((category, index) => (
                              <div
                                key={index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelect(category)}
                              >
                                {category.title}
                              </div>
                            ))
                          ) : (
                            <div>No Category Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="xl"
                      type="text"
                      value={citySelected}
                      onClick={() => handleInputClick("city")}
                      onChange={handleCityChange}
                      className="flex-grow-1 rounded-0 my-1"
                      placeholder="City"
                    />
                    {showCitySelect && (
                      <div
                        ref={cityDropdownRef}
                        className="position-absolute h-200px overflow-auto heromanual-dropdown"
                        style={{ zIndex: 1, width: "27%" }}
                      >
                        <DropdownMenu className="d-flex flex-wrap p-1 border w-100">
                          {cityListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : cityListError ? (
                            <div>Error loading cities. Please try again.</div>
                          ) : cityList?.data?.cities_with_states?.length ? (
                            cityList.data.cities_with_states.map((name, index) => (
                              <div
                                key={name.city_id || index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCitySelect(name)}
                              >
                                {/* {console.log(cityList?.data?.cities_with_states)} */}
                                {name.city_name}, {name.state_name}
                              </div>
                            ))
                          ) : (
                            <div>No Cities Found</div>
                          )}
                        </DropdownMenu>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    <Button
                      color="primary"
                      className="rounded-end flex-grow-1 rounded-0 fs-6 my-1"
                      block
                      onClick={handleSearch}
                      disabled={loading}
                      style={{ padding: "10px 5px", height: "55px" }}
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Search DJs"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
        {lockScreen === true && (
          <div style={{ zIndex: 1 }} className="flex-column align-center justify-center">
            <div className="my-1 mb-2 h-150px">
              <img src={FrontPageLogo} alt="IPDM Logo" style={{ height: "inherit" }} />
            </div>
            <BlockTitle className="justify-center text-white">
              <span style={{ fontSize: "25px" }}>Need A DJ?</span>
            </BlockTitle>
            <div className="justify-center py-1">
              <Button
                className="fs-2 p-4 px-5"
                color="primary"
                style={{ borderRadius: "50px" }}
                onClick={handleHeroSection}
              >
                Search Now
              </Button>
            </div>
            <div className="d-flex justify-center">
              <span className="text-white fs-4 fw-semibold">Are You A DJ?</span>
              <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`}>
                <span className="text-primary fs-4 fw-semibold ms-1">Sign Up Here!</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroSection;
