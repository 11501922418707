import React, { useState } from "react";
import { Accordion, Col, Collapse, Row } from "reactstrap";
import { BlockTitle, PreviewCard } from "../../../components/Component";
import { Link } from "react-router-dom";

const AccordianSection = ({ className, variation }) => {
  const [isOpen, setIsOpen] = useState("2");
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  return (
    <>
      <Row className="g-0 align-center h-100">
        <Col md={6}>
          <div className="h-100 justify-center flex-column p-5">
            <BlockTitle className="mb-3">Why Choose {process.env.REACT_APP_TITLE} ?</BlockTitle>
            <div className="mb-3">
              At{" "}
              <Link to={`${process.env.REACT_APP_PUBLIC_URL}`}>
                <span className="text-primary fw-medium" style={{ cursor: "pointer" }}>
                  {process.env.REACT_APP_TITLE}{" "}
                </span>{" "}
              </Link>
              , we believe that every event deserves to be unforgettable. With over 60 years of combined experience in
              the DJ industry, our comprehensive range of services is designed to create the perfect atmosphere for any
              occasion, whether it's an intimate wedding, a high-energy corporate event, or a lively private party. Our
              team of professional DJs, MCs, and event specialists is committed to delivering an exceptional experience
              that your guests will rave about.
            </div>
          </div>
        </Col>
        <Col md={6}>
          <PreviewCard className="border-0 justify-center h-450px">
            <div
              className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}
            >
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("2")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "2" ? "title text-primary" : "title"}>Professional Expertise</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Our DJs are more than just music mixers—they’re entertainers who know how to read the crowd, keep
                      the energy up, and ensure everyone has a great time. With over six decades of experience, we
                      tailor our approach to suit the unique needs of your event, from the first consultation to the
                      final song.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "7" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("7")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "7" ? "title text-primary" : "title"}>Cutting-Edge Technology</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "7" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      We use top-of-the-line audio, visual, and lighting equipment to ensure your event not only sounds
                      amazing but looks spectacular as well. Our techsavvy team handles all the details, so you can
                      focus on enjoying your special day.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("1")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "1" ? "title text-primary" : "title"}>Customizable Experiences</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      We understand that no two events are the same. That’s why we offer a wide range of customizable
                      services, from personalized playlists and monogram lighting to live streaming and interactive
                      games. Whatever your vision, we’ll help bring it to life.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("3")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "3" ? "title text-primary" : "title"}>Comprehensive Service Range</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Whether you’re planning a wedding, corporate event, or private party, we’ve got you covered. Our
                      extensive service offerings ensure that every aspect of your event is expertly managed and
                      executed to perfection.
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </PreviewCard>
        </Col>
      </Row>
    </>
  );
};

export default AccordianSection;
