import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useThemeUpdate } from "../../../provider/Theme";
import Cookies from "js-cookie";
import User from "../user/User";

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  const authTokenChk = Cookies.get("authToken");
  function cookieVariable() {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            {/* Dashboard */}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/`} onClick={toggle} className="nav-link">
                <span className="list-apps-media">
                  <Icon name="home"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/home" || currentUrl === "/"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  Home
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="about"
                onClick={toggle}
                className={currentUrl === "/about" ? "text-primary nav-link text-dark" : "nav-link text-dark"}
              >
                <span className="list-apps-media">
                  <Icon name="info-i"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/about" ? "text-primary text-dark list-apps-title" : "text-dark list-apps-title"
                  }
                >
                  About
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="dj-section"
                onClick={toggle}
                className={currentUrl === "/dj-section" ? "text-primary nav-link text-dark" : "nav-link text-dark"}
              >
                <span className="list-apps-media">
                  <Icon name="play"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/dj-section"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  DJ Only
                </span>
              </Link>
            </li>
            <li>
              <Link to={`https://blogs.ipartydjcrm.com/`} onClick={toggle} className="nav-link text-dark" target="_blank">
                <span className="list-apps-media">
                  <Icon name="blogger-fill"></Icon>
                </span>
                <span className="text-dark list-apps-title">Blog</span>
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                onClick={toggle}
                className={currentUrl === "/contact" ? "text-primary nav-link text-dark" : "nav-link text-dark"}
              >
                <span className="list-apps-media">
                  <Icon
                    name="contact"
                  ></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/contact" ? "text-primary text-dark list-apps-title" : "text-dark list-apps-title"
                  }
                >
                  Contact
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="business-portal"
                onClick={toggle}
                className={currentUrl === "/business-portal" ? "text-primary nav-link text-dark" : "nav-link text-dark"}
              >
                <span className="list-apps-media">
                  <Icon
                    name="cc-new"
                  ></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/business-portal" ? "text-primary text-dark list-apps-title" : "text-dark list-apps-title"
                  }
                >
                  BusinessPortal
                </span>
              </Link>
            </li>
            {!authTokenChk && (
              <li className="nav-item">
                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}login`} className="nav-link" onClick={cookieVariable}>
                  <button className="btn btn-primary">Sign In</button>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
