import React, { useState } from "react";
import { Block, BlockTitle, PreviewCard } from "../../components/Component";
import { Collapse } from "reactstrap";
import Content from "../../layout/content/Content";

const FAQ = ({ className, variation }) => {
  const [isOpen, setIsOpen] = useState("0");
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  return (
    <div id="check" className=" border">
      <Block size="lg">
        <Content className="p-0">
          <BlockTitle page className="mb-2 text-primary" tag="h3">
            <span>FAQ's</span>
          </BlockTitle>
          <PreviewCard className="border-0">
            <div
              className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}
            >
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("2")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "2" ? "title text-primary" : "title"}>
                    What is {process.env.REACT_APP_TITLE} ?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      {process.env.REACT_APP_TITLE} is a comprehensive DJ booking service designed for both clients and
                      DJs. For clients, it offers an easy-to-use platform to find and book professional DJs for any
                      event, ensuring a seamless experience from start to finish. For DJs, it provides tools to manage
                      bookings, communicate with clients, and grow their business. With {process.env.REACT_APP_TITLE} ,
                      both clients and DJs can enjoy a smooth, efficient, and reliable booking process.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "7" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("7")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "7" ? "title text-primary" : "title"}>How Do I Book a DJ?</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "7" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Booking a DJ is simple. Browse through our list of DJs, read reviews and ratings, and select the
                      one that best fits your event. Click the "Book Now" button on the DJ's profile and follow the
                      prompts to complete your booking.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("1")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "1" ? "title text-primary" : "title"}>
                    How Secure Is the Online Payment Process?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      We prioritize the security of your payments by using encrypted payment gateways and secure
                      transaction protocols. Your payment information is protected, ensuring a safe and secure
                      transaction every time you book a DJ through our platform.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("3")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "3" ? "title text-primary" : "title"}>
                    Can I See Reviews and Ratings of the DJs?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Yes, you can! Our platform features detailed profiles for each DJ, including reviews and ratings
                      from previous clients. This helps you make an informed decision based on real experiences from
                      other customers.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "5" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("5")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "5" ? "title text-primary" : "title"}>
                    How Do I Know the DJ Will Play the Music I Want?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "5" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      During the booking process, you can provide a list of preferred songs and genres. Our DJs are
                      skilled in accommodating various musical tastes and will work with you to ensure your event has
                      the perfect soundtrack.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "6" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("6")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "6" ? "title text-primary" : "title"}>
                    What Happens If There Are Technical Issues During the Event?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "6" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Our DJs come prepared with backup equipment and technical support to handle any issues that may
                      arise. We aim to ensure your event runs smoothly without any disruptions.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "8" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("8")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "8" ? "title text-primary" : "title"}>
                    Can I Communicate with the DJ Before the Event?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "8" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Absolutely! After booking, you can communicate with your DJ to discuss event details, music
                      preferences, and any special requests. Our platform provides an easy way to message your DJ
                      directly.
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </PreviewCard>
        </Content>
      </Block>
    </div>
  );
};

export default FAQ;
