import React, { useEffect, useRef, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Component";
import {
  Badge,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Content from "../../layout/content/Content";
import classnames from "classnames";
import placeHolderImg from "../../assets/images/placeHolderImg.png";
import ReactPlayer from "react-player";
import "../../css/style.css";
import thumbnail from "../../images/ThumbnailImage.jpg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Fancybox from "./Fancybox";
import NoResult from "../../images/imageCarousel.png";
import NoResultVideo from "../../images/NoResultVideo.png";
import NoResultMixes from "../../images/NoResultMixes.png";

const Portfolio = ({ companyData, companyDataLoading }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeVideo, setActiveVideo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryChk, setSelectedCategoryChk] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoValue, setVideoValue] = useState(null);
  const [mixersValue, setMixersValue] = useState([]);
  const [soundMixersValue, setSoundMixersValue] = useState([]);
  const [mixCloudMixersValue, setMixCloudMixersValue] = useState([]);
  const [selectedMixesChk, setSelectedMixesChk] = useState("Mixes");
  const [playingMix, setPlayingMix] = useState(null);

  const togglevid = () => setVideoOpen(!videoOpen);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const categories = companyData?.images ? Object.keys(companyData.images) : [];
  const filteredImages = companyData?.images ? companyData?.images : {};

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === "All" ? null : category);
    setSelectedCategoryChk(category);
  };
  const handleVideoClick = (index) => {
    setActiveVideo(index === activeVideo ? null : index);
  };

  const closeVideoModal = () => {
    setActiveVideo(null);
  };

  useEffect(() => {
    const uploadVideo = companyData?.videos;
    if (uploadVideo) {
      setVideosData(uploadVideo);
    } else {
      setVideosData([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixers = companyData?.mixesData?.[0]?.mixes || [];
    if (uploadMixers) {
      setMixersValue(uploadMixers);
    } else {
      setMixersValue([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixersLinks = companyData?.mixesData?.[0]?.soundcloud || [];
    try {
      setSoundMixersValue(uploadMixersLinks);
    } catch (error) {
      console.error("Failed to parse mixers links:", error);
      setSoundMixersValue([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixersLinks = companyData?.mixesData?.[0]?.mixcloud || [];
    try {
      setMixCloudMixersValue(uploadMixersLinks);
    } catch (error) {
      console.error("Failed to parse mixers links:", error);
      setMixCloudMixersValue([]);
    }
  }, [companyData]);

  const handleMixesSelect = (mixcategory) => {
    setSelectedMixesChk(mixcategory);
  };
  const handleMixPlay = (index) => {
    setPlayingMix(index);
  };

  return (
    <div id="portfolio">
      <Block size="lg">
        <PreviewCard>
          <BlockHead size="sm" className="my-2 mx-2">
            <BlockBetween className="g-3 justify-center">
              <BlockHeadContent>
                <BlockTitle page>
                  <h3 className="text-center">Portfolio</h3>
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Nav tabs className="mt-n3 justify-center">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                Mixes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                Videos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeTab === "3" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("3");
                }}
              >
                Images
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="p-0 m-0">
            <TabPane tabId="1">
              <Content>
                <Block>
                  <div>
                    {mixersValue.length === 0 ? (
                      ""
                    ) : (
                      <span onClick={() => handleMixesSelect("Mixes")}>
                        <Badge
                          color="outline-primary"
                          className={
                            selectedMixesChk == "Mixes"
                              ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                              : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Mixes
                        </Badge>
                      </span>
                    )}
                    {soundMixersValue.length === 0 ? (
                      ""
                    ) : (
                      <span onClick={() => handleMixesSelect("Sound-Cloud")}>
                        <Badge
                          color="outline-primary"
                          className={
                            selectedMixesChk == "Sound-Cloud"
                              ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                              : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Sound-Cloud
                        </Badge>
                      </span>
                    )}
                    {mixCloudMixersValue.length === 0 ? (
                      ""
                    ) : (
                      <span onClick={() => handleMixesSelect("Mix-Cloud")}>
                        <Badge
                          color="outline-primary"
                          className={
                            selectedMixesChk == "Mix-Cloud"
                              ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                              : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Mix-Cloud
                        </Badge>
                      </span>
                    )}
                  </div>
                  {selectedMixesChk === "Mixes" ? (
                    <Row className="g-3 mt-1">
                      {mixersValue &&
                        mixersValue.map((mixer, index) => (
                          <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                            <Card
                              className="card-bordered gallery align-center w-100 border-0"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="cardaudio w-85 justify-center">
                                <div className="topaudio">
                                  <div className="pfp">
                                    <div className="playingaudio">
                                      <div className="greenlineaudio line-1audio"></div>
                                      <div className="greenlineaudio line-2audio"></div>
                                      <div className="greenlineaudio line-3audio"></div>
                                      <div className="greenlineaudio line-4audio"></div>
                                      <div className="greenlineaudio line-5audio"></div>
                                    </div>
                                  </div>
                                  <div className="textsaudio">
                                    <p className="title-1audio">{mixer.title || `Audio - ${index + 1}`}</p>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <ReactPlayer
                                    playing={index === playingMix}
                                    onPlay={() => handleMixPlay(index)}
                                    url={mixer?.path}
                                    controls={true}
                                    width="100%"
                                    height="30px"
                                  />
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      {!mixersValue.length && (
                        <div className="d-flex justify-content-center align-items-center">
                          <Card className="p-2">
                            <div className="justify-center  ">
                              <img src={NoResultMixes} alt="No-Result" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </Row>
                  ) : selectedMixesChk === "Sound-Cloud" ? (
                    <Row className="g-3 mt-1">
                      {companyDataLoading ? (
                        <div>
                          <ShimmerSimpleGallery card imageHeight={100} />
                        </div>
                      ) : soundMixersValue.length > 0 ? (
                        soundMixersValue.map((mixer, index) => (
                          <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                            <div className="mt-2 w-100" dangerouslySetInnerHTML={{ __html: mixer.mixlink }} />
                          </Col>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <Card className="p-2">
                            <div className="justify-center  ">
                              <img src={NoResultMixes} alt="No-Result" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </Row>
                  ) : selectedMixesChk === "Mix-Cloud" ? (
                    <Row className="g-3 mt-1">
                      {companyDataLoading ? (
                        <div>
                          <ShimmerSimpleGallery card imageHeight={100} />
                        </div>
                      ) : mixCloudMixersValue.length > 0 ? (
                        mixCloudMixersValue.map((mixer, index) => (
                          <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                            <div className="mt-2 w-100" dangerouslySetInnerHTML={{ __html: mixer.mixlink }} />
                          </Col>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <Card className="p-2">
                            <div className="justify-center  ">
                              <img src={NoResultMixes} alt="No-Result" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </Row>
                  ) : (
                    ""
                  )}
                </Block>
              </Content>
            </TabPane>
            <TabPane tabId="2">
              <Content>
                <Block>
                  <Row className="g-3 mt-1">
                    {videosData.map((video, index) => (
                      <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                        <Card
                          className="card-bordered gallery align-center w-85 border-0"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="video justify-center">
                            <div className="player-wrapper">
                              <img
                                className="video-poster"
                                src={video.thumbnail ? video.thumbnail : thumbnail}
                                alt="DJ Video"
                              />
                              <div
                                className="video-play popup-video start-0 video-modal"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  togglevid();
                                  setVideoValue(video?.path);
                                }}
                              >
                                <div class="vidbutton">
                                  <svg
                                    viewBox="0 0 448 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    width="20px"
                                  >
                                    <path
                                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))}
                    {videosData.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <Card className="p-2">
                          <div className="justify-center  ">
                            <img src={NoResultVideo} alt="No-Result" height="200px" />
                          </div>
                        </Card>
                      </div>
                    )}
                  </Row>
                </Block>
              </Content>
            </TabPane>
            <TabPane tabId="3">
              <Content>
                <Block>
                  {companyData?.images ? (
                    <div>
                      {companyData?.images.length === 0 ? (
                        ""
                      ) : (
                        <div>
                          <span onClick={() => handleCategorySelect("All")}>
                            <Badge
                              color="outline-primary"
                              className={
                                selectedCategoryChk == "All"
                                  ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                  : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              All
                            </Badge>
                          </span>
                          {categories.map((category, index) => (
                            <span key={index} onClick={() => handleCategorySelect(category)}>
                              <Badge
                                color="outline-primary"
                                className={
                                  selectedCategoryChk == category
                                    ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                    : "fs-5 me-1 p-1 rounded-5 px-4 btn-dim btn-outline-light"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {category}
                              </Badge>
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <Row className="g-3 mt-3 h-500px overflow-auto" style={{ scrollbarWidth: "none" }}>
                    <Fancybox
                      options={{
                        Carousel: {
                          infinite: false,
                        },
                      }}
                    >
                      {(selectedCategory ? filteredImages[selectedCategory] : Object.values(filteredImages))
                        .flat()
                        .map((imageUrl, index) => (
                          <Col key={index} sm={6} lg={3} xxl={3} className="m-0 p-0">
                            <Card className="gallery">
                              <div
                                className="col-4 justify-center mt-0 w-100"
                                key={index}
                                style={{ padding: "1px 1px", height: "40vh" }}
                              >
                                {" "}
                                <a
                                  data-fancybox="gallery"
                                  href={imageUrl?.url || NoResult}
                                  className="justify-center h-100 w-100"
                                >
                                  <img
                                    alt="DJ Image"
                                    src={imageUrl?.url || NoResult}
                                    className="object-fit-cover w-100"
                                    // style={{ height: "200px" }}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                        ))}
                    </Fancybox>
                    {Object.keys(filteredImages).length === 0 && (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <Card className="p-2">
                          <div className="justify-center">
                            <img src={NoResult} alt="No-Result" height="200px" />
                          </div>
                        </Card>
                      </div>
                    )}
                  </Row>
                </Block>
              </Content>
            </TabPane>
          </TabContent>
        </PreviewCard>
      </Block>
      <Modal size="lg" isOpen={videoOpen} toggle={togglevid}>
        <ModalHeader
          toggle={togglevid}
          close={
            <button className="close" onClick={togglevid}>
              <Icon name="cross" />
            </button>
          }
        >
          Video Preview
        </ModalHeader>
        <ModalBody className="p-0" style={{ height: "500px" }}>
          {companyDataLoading ? (
            <div>
              <ShimmerSimpleGallery card imageHeight={100} />
            </div>
          ) : (
            <ReactPlayer controls url={videoValue} className="react-player" width="100%" height="100%" />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Portfolio;
