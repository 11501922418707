import React from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import djImg from "../../images/rolesection1.jpg";
import crowdImg from "../../images/rolesection2.jpg";
import { BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";

const RoleSection = () => {
  return (
    <Card className="py-4">
      <BlockTitle className="mt-1 nk-block-title title fs-2 text-center">JOIN US</BlockTitle>
      <div className="text-center mb-2 px-2">
        <Card className="p-2">
          <span className="px-5 fs-6 d-none d-md-block">
            Are you a DJ or DJ company looking to connect with clients for weddings, private parties, school events,
            corporate functions, or other exciting gigs? Join  {process.env.REACT_APP_TITLE}{" "}  today and become part of a vibrant
            community where clients search for the perfect DJ to make their event unforgettable. Whether you’re offering
            entertainment for a car show, club night, or any unique gathering,  {process.env.REACT_APP_TITLE}{" "}  allows you to showcase
            your talent and land bookings. On the flip side, if you're someone looking for the right DJ to create the
            perfect atmosphere for your special occasion,  {process.env.REACT_APP_TITLE}{" "}  offers a streamlined search to help you find
            the perfect match—whether it’s a laid-back private event or an energetic corporate bash. Sign up now and get
            started!
          </span>
          <span className="px-2 fs-12px d-md-none">
            Are you a DJ or DJ company looking to connect with clients for weddings, private parties, school events,
            corporate functions, or other exciting gigs? Join  {process.env.REACT_APP_TITLE}{" "}  today and become part of a vibrant
            community where clients search for the perfect DJ to make their event unforgettable. Whether you’re offering
            entertainment for a car show, club night, or any unique gathering,  {process.env.REACT_APP_TITLE}{" "}  allows you to showcase
            your talent and land bookings. On the flip side, if you're someone looking for the right DJ to create the
            perfect atmosphere for your special occasion,  {process.env.REACT_APP_TITLE}{" "}  offers a streamlined search to help you find
            the perfect match—whether it’s a laid-back private event or an energetic corporate bash. Sign up now and get
            started!
          </span>
        </Card>
      </div>
      <Row className="h-400px g-0">
        <Col className="p-2" md={6}>
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${crowdImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              className=""
              style={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            />
            <div
              className="d-flex flex-column justify-center align-center h-100 text-white"
              style={{ position: "relative", zIndex: 3 }}
            >
              <p className="mb-0">Start Your journey as client</p>
              <BlockTitle className="text-center fs-4 px-3">
                Book Your Perfect DJ using  {process.env.REACT_APP_TITLE}{" "}  Platform
              </BlockTitle>
              <Link to={`/client-section`} className="btn btn-primary">
                Register as Client!
              </Link>
            </div>
          </div>
        </Col>
        <Col className="p-2" md={6}>
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${djImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              className=""
              style={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            />
            <div
              className="d-flex flex-column justify-center align-center h-100 text-white"
              style={{ position: "relative", zIndex: 3 }}
            >
              <p className="mb-0">Start Your Journey as a DJ Company</p>
              <BlockTitle className="text-center fs-4 px-3">
                Create Your DJ Company Profile & Register with  {process.env.REACT_APP_TITLE}{" "} 
              </BlockTitle>
              <Link to={`/dj-section`} className="btn btn-primary">
                Register as DJ!
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default RoleSection;
