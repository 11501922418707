import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Badge,
  Button,
  Col,
  DropdownMenu,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import MultiRangeSlider from "multi-range-slider-react";
import debounce from "lodash.debounce";
import { getAllCities, getAllCount } from "../../http/get/getApi";
import { useQuery } from "react-query";
import "../../css/style.css";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import Nouislider from "nouislider-react";
import wNumb from "wnumb";
import { Icon } from "../../components/Component";
import Cookies from "js-cookie";
import classnames from "classnames";

const Filter = ({
  selectedServices,
  selectedGeners,
  serviceData,
  genresData,
  setBudget,
  refetchCompaniesList,
  handleServiceChange,
  handleGenerChange,
  setMultiValue,
  setCityData,
  multiValue,
}) => {
  const [minValue, setMinValue] = useState(100);
  const [maxValue, setMaxValue] = useState(500);
  const [minLable, setMinLable] = useState(100);
  const [maxLable, setMaxLable] = useState(500);
  const [name, setName] = useState("");
  const [sliderKey, setSliderKey] = useState(Date.now());
  const [sliderValues, setSliderValues] = useState([minLable, maxLable]);
  const timeoutRef = useRef();
  const cityDropdownRef = useRef(null);
  const debouncedRefetch = useCallback(debounce(refetchCompaniesList, 1000), [refetchCompaniesList]);
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [citySelected, setCitySelected] = useState("");
  const [city, setCity] = useState("");
  const [cityCancel, setCityCancel] = useState(false); // For checking city text present
  const [allSearchCancel, setAllSearchCancel] = useState(false); // For checking company, username, address text present
  const [modalTab, setModalTab] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = () => setModalTab(!modalTab);

  const handleInputChange = (e) => {
    setName(e.target.value);
    const value = e.target.value;
    if (value.length > 0) {
      setAllSearchCancel(true);
    } else {
      setAllSearchCancel(false);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setMultiValue(value);
    }, 1000);
  };

  useEffect(() => {
    debouncedRefetch();
  }, [minValue, maxValue, debouncedRefetch]);

  const handleBudgetChange = useCallback(
    (minVal, maxVal) => {
      setBudget(`${minVal},${maxVal}`);
      debouncedRefetch();
    },
    [setBudget]
  );

  const handleInputFieldChange = useCallback(
    (e, type) => {
      let value = e.target.value ? parseInt(e.target.value) : 0;
      if (value < 0) {
        value = 0;
      } else if (value > 500) {
        value = 500;
      }
      if (type === "min") {
        if (value < maxLable) {
          setMinLable(value);
          handleBudgetChange(value, maxLable);
        } else {
          setMinLable(value);
          handleBudgetChange(maxLable - 1, maxLable);
        }
      } else if (type === "max") {
        if (value > minLable) {
          setMaxLable(value);
          handleBudgetChange(minLable, value);
        } else {
          setMaxLable(value);
          handleBudgetChange(minLable, minLable + 1);
        }
      }
    },
    [minLable, maxLable, handleBudgetChange]
  );

  const handleInput = useCallback(
    (values) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        let [minVal, maxVal] = values.map((value) => parseInt(value));
        if (minVal === maxVal) {
          maxVal = maxVal < 500 ? maxVal + 1 : minVal - 1;
        }
        handleBudgetChange(minVal, maxVal);
        setMinLable(minVal);
        setMaxLable(maxVal);
      }, 1000);
    },
    [handleBudgetChange]
  );

  const handleServiceCheckboxChange = useCallback(
    (serviceId) => {
      handleServiceChange((prevSelectedServices) => {
        const servicesArray = prevSelectedServices || [];
        if (servicesArray.includes(serviceId)) {
          return servicesArray.filter((s) => s !== serviceId);
        } else {
          return [...servicesArray, serviceId];
        }
      });
      debouncedRefetch();
    },
    [handleServiceChange, debouncedRefetch]
  );

  const handleGenereCheckboxChange = useCallback(
    (genereId) => {
      handleGenerChange((prevSelectedGeners) => {
        const generArray = prevSelectedGeners || [];
        if (generArray.includes(genereId)) {
          return generArray.filter((s) => s !== genereId);
        } else {
          return [...generArray, genereId];
        }
      });
      debouncedRefetch();
    },
    [handleGenerChange, debouncedRefetch]
  );

  const {
    data: listCount,
    isLoading: listCountLoading,
    isError: listCountError,
  } = useQuery({
    queryKey: ["get-list-count"],
    queryFn: () => getAllCount(),
  });

  const handleCityChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCityCancel(true);
    } else {
      setCityCancel(false);
    }
    setCitySelected(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCity(value);
    }, 1000);
  };
  const {
    data: cityList,
    isLoading: cityListLoading,
    isError: cityListError,
  } = useQuery({
    queryKey: ["get-city-list", city],
    queryFn: () => getAllCities({ city: city }),
  });

  const handleCitySelect = (name) => {
    setCityCancel(true);
    const cityNameWithState = `${name?.city_name}, ${name?.state_name}`;
    setCitySelected(cityNameWithState);
    setCity(name?.city_name);
    setCityData(name?.city_name);
    setShowCitySelect(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setShowCitySelect(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let getCity;
  let getCityId;
  useEffect(() => {
    getCity = Cookies.get("cityData") || "";
    getCityId = Cookies.get("cityId") || "";
    setCitySelected(getCity);
    setCityData(getCityId);
    Cookies.remove("cityData");
    Cookies.remove("cityId");
  }, []);

  const clearFilters = () => {
    setName("");
    setBudget(null);
    setMultiValue(null);
    handleServiceChange([]);
    handleGenerChange([]);
    setMinValue(0);
    setMaxValue(500);
    setMaxLable(500);
    setMinLable(0);
    setSliderKey(Date.now());
    setCitySelected("");
    setCityData("");
  };

  const clearInput = (value) => {
    if (value === 1) {
      console.log(value);
      setMultiValue("");
      setName("");
      setAllSearchCancel(false);
    } else if (value === 2) {
      console.log(value);
      setCitySelected("");
      setCityData("");
      setCity("");
      setCityCancel(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-between mx-3 align-center border-bottom pb-2">
        <div className="align-center">
          <h5 className="font-weight-bold">Filter</h5>
        </div>
        <div className="justify-end">
          <Button color="primary" onClick={clearFilters} className="px-1 w-100 justify-center" id="filter">
            Clear All
          </Button>
        </div>
      </div>

      <div className="p-1">
        <div className="border-bottom pb-2 row mt-1">
          <Label className="mb-0">Select Search</Label>
          <div className="App mt-2 mb-1 col-lg-12 col-sm-6">
            <div className="input-group border border-1 rounded-2">
              <Input
                style={{ cursor: "pointer" }}
                size="lg"
                type="text"
                maxLength={50}
                value={name}
                onChange={(e) => {
                  handleInputChange(e);
                  // setName(e.target.value);
                }}
                className="flex-grow-1 border-0"
                placeholder="Companyname/Username"
              />
              {allSearchCancel && (
                <div className="input-group-append" onClick={() => clearInput(1)}>
                  <span className="input-group-text bg-white p-1 fs-3" id="basic-addon1">
                    <Icon name="cross-circle"></Icon>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="App mt-2 mb-1 col-lg-12 col-sm-6">
            {/* <Label>Select City</Label> */}
            <div className="input-group border border-1 rounded-2">
              <Input
                style={{ cursor: "pointer" }}
                size="lg"
                type="text"
                value={citySelected}
                onClick={() => setShowCitySelect(true)}
                onChange={(e) => {
                  handleCityChange(e);
                }}
                className="flex-grow-1  border-0"
                placeholder="Select City"
              />
              {cityCancel && (
                <div className="input-group-append" onClick={() => clearInput(2)}>
                  <span className="input-group-text bg-white p-1 fs-3" id="basic-addon1">
                    <Icon name="cross-circle"></Icon>
                  </span>
                </div>
              )}
            </div>

            {showCitySelect && (
              <div
                ref={cityDropdownRef}
                className="position-absolute h-200px overflow-auto mt-1"
                style={{ width: "360px" }}
              >
                <DropdownMenu className="d-flex flex-wrap p-1 border w-100">
                  {cityListLoading ? (
                    <div className="mx-1 align-center">
                      <Spinner size="sm" color="light" />
                      <span className="px-1">Loading...</span>
                    </div>
                  ) : cityListError ? (
                    <div>Error loading cities. Please try again.</div>
                  ) : cityList?.data?.cities_with_states?.length ? (
                    cityList.data.cities_with_states.map((name, index) => (
                      <div
                        key={name.city_id || index}
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCitySelect(name)}
                      >
                        {name.city_name}, {name.state_name}
                      </div>
                    ))
                  ) : (
                    <div>No Cities Found</div>
                  )}
                </DropdownMenu>
              </div>
            )}
          </div>
        </div>
        <Row>
          <Col sm={6} lg={12}>
            <div className="App my-3">
              <Label>Select Budget</Label>
              {/* <div className="leaflet-bar rounded-5 py-2 px-4">
              <div className="d-flex mx-1 justify-center">
                <div className="lead-text p-0">Filtered Range :</div>
                <div className=" p-0 d-flex">
                  {minLable === "---" && maxLable === "---" ? (
                    <>
                      <div className="mx-1 px-1">No Range Selected</div>
                    </>
                  ) : (
                    <>
                      <div className="mx-1 text-white bg-primary rounded-3 px-1">${minLable}</div>
                      <div className="mx-1 text-white bg-primary rounded-3 px-1">${maxLable}</div>
                    </>
                  )}
                </div>
              </div>
              <MultiRangeSlider
                key={sliderKey} // Add the key prop to force re-render
                className="custom-multi-range-slider shadow-none border-0 pt-3 px-2"
                min={0}
                max={500}
                minValue={minValue}
                maxValue={maxValue}
                ruler={false}
                stepOnly="true"
                onChange={handleInput}
                barInnerColor="#4FBB26"
                // onInput={handleInput}
              />
            </div> */}
              <div className="form-control-wrap py-1 px-4 mb-2 mx-1">
                <Nouislider
                  style={{ cursor: "pointer" }}
                  key={sliderKey}
                  className="form-range-slider"
                  accessibility
                  tooltips={true}
                  connect={true}
                  step={10}
                  keyboardSupport={true}
                  // start={[0, 500]}
                  start={[minLable, maxLable]}
                  range={{
                    min: minValue,
                    max: maxValue,
                  }}
                  format={wNumb({
                    decimals: 0, // Ensure values are integers
                  })}
                  onChange={handleInput}
                ></Nouislider>
              </div>
              <div className="d-flex justify-center align-center mx-1">
                <span style={{ width: "90px" }} className="mx-1">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="sign-dollar" />
                    </div>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="sm"
                      type="number"
                      value={minLable}
                      onChange={(e) => {
                        handleInputFieldChange(e, "min");
                      }}
                      className="flex-grow-1 rounded-2 w-100 pt-1"
                      placeholder="Minimum Value"
                    />
                  </div>
                </span>
                <span className="mx-1">to</span>
                <span style={{ width: "90px" }} className="mx-1">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="sign-dollar" />
                    </div>
                    <Input
                      style={{ cursor: "pointer" }}
                      size="sm"
                      type="number"
                      value={maxLable}
                      onChange={(e) => {
                        handleInputFieldChange(e, "max");
                      }}
                      className="flex-grow-1 rounded-2 w-100 pt-1"
                      placeholder="Maximum Value"
                      // min={minLable}
                      max={500}
                    />
                  </div>
                </span>
              </div>
            </div>
          </Col>
          <Col sm={6} lg={12}>
            <div className="d-lg-none">
              <span onClick={toggleTab}>
                <Badge
                  color="outline-primary"
                  className="fs-5 p-1 rounded-5 justify-center text-body mt-3 px-2"
                  style={{ cursor: "pointer", width: "200px" }}
                >
                  + Services & Geners
                </Badge>
              </span>
              <Modal isOpen={modalTab} toggle={toggleTab}>
                <ModalHeader
                  toggle={toggleTab}
                  close={
                    <button className="close" onClick={toggleTab}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Services & Generes
                </ModalHeader>
                <ModalBody>
                  <Nav tabs className="mt-n3">
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setActiveTab("1");
                        }}
                      >
                        Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setActiveTab("2");
                        }}
                      >
                        Genres
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="genres my-3 pb-2 border-bottom">
                        <Label>Services</Label>
                        <div className="h-200px overflow-auto">
                          {serviceData.map((service, index) => (
                            <div
                              key={index}
                              className="form-check form-check-lg align-center py-1 bghover"
                              style={
                                selectedServices?.includes(service?.id)
                                  ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() => handleServiceCheckboxChange(service?.id)}
                            >
                              <input
                                className="form-check-input border mt-0 align-center ps-2"
                                type="checkbox"
                                name="checkbox"
                                id={`service${index}`}
                                checked={selectedServices?.includes(service?.id) || false}
                                onChange={() => {}}
                                style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                              />
                              <label className="form-check-label ml-2 fs-6 rounded-3">
                                {service?.title}
                                <span>
                                  {" "}
                                  (
                                  {listCount && listCount.data && listCount.data.service_counts
                                    ? Object.entries(listCount.data.service_counts).map(
                                        ([servicescount, count], index) =>
                                          servicescount === service?.title ? (
                                            <span key={`service-${index}`}>{count}</span>
                                          ) : null
                                      )
                                    : ""}
                                  )
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="generes my-3 pb-2 border-bottom">
                        <Label>Genres</Label>
                        <div className="h-200px overflow-auto">
                          {genresData.map((genere, index) => (
                            <div
                              key={index}
                              className="form-check form-check-lg align-center py-1 bghover"
                              style={
                                selectedGeners?.includes(genere?.id)
                                  ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                                  : { cursor: "pointer" }
                              }
                              onClick={() => handleGenereCheckboxChange(genere?.id)}
                            >
                              <input
                                className="form-check-input border mt-0 align-center ps-2"
                                type="checkbox"
                                name="checkbox"
                                id={`genere${index}`}
                                checked={selectedGeners?.includes(genere?.id) || false}
                                onChange={() => {}}
                                style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                              />
                              <label className="form-check-label ml-2 fs-6 rounded-3">
                                {genere?.title}
                                <span>
                                  {" "}
                                  (
                                  {listCount && listCount.data && listCount.data.genre_counts
                                    ? Object.entries(listCount.data.genre_counts).map(([generecount, count], index) => (
                                        <span key={`genre-${index}`}>{generecount === genere?.title ? count : ""}</span>
                                      ))
                                    : ""}
                                  )
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </ModalBody>
              </Modal>
            </div>
            <Row className="d-lg-block d-none">
              <Col md={6} lg={12}>
                {" "}
                <div className="genres my-3 pb-2 border-bottom">
                  <Label>Services</Label>
                  <div className="h-200px overflow-auto">
                    {serviceData.map((service, index) => (
                      <div
                        key={index}
                        className="form-check form-check-lg align-center py-1 bghover"
                        style={
                          selectedServices?.includes(service?.id)
                            ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                            : { cursor: "pointer" }
                        }
                        onClick={() => handleServiceCheckboxChange(service?.id)}
                      >
                        <input
                          className="form-check-input border mt-0 align-center ps-2"
                          type="checkbox"
                          name="checkbox"
                          id={`service${index}`}
                          checked={selectedServices?.includes(service?.id) || false}
                          onChange={() => {}}
                          style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                        />
                        <label className="form-check-label ml-2 fs-6 rounded-3">
                          {service?.title}
                          <span>
                            {" "}
                            (
                            {listCount && listCount.data && listCount.data.service_counts
                              ? Object.entries(listCount.data.service_counts).map(([servicescount, count], index) =>
                                  servicescount === service?.title ? (
                                    <span key={`service-${index}`}>{count}</span>
                                  ) : null
                                )
                              : ""}
                            )
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col md={6} lg={12}>
                <div className="generes my-3 pb-2 border-bottom">
                  <Label>Genres</Label>
                  <div className="h-200px overflow-auto">
                    {genresData.map((genere, index) => (
                      <div
                        key={index}
                        className="form-check form-check-lg align-center py-1 bghover"
                        style={
                          selectedGeners?.includes(genere?.id)
                            ? { backgroundColor: "#e6eef5", cursor: "pointer", color: "#4fbb26" }
                            : { cursor: "pointer" }
                        }
                        onClick={() => handleGenereCheckboxChange(genere?.id)}
                      >
                        <input
                          className="form-check-input border mt-0 align-center ps-2"
                          type="checkbox"
                          name="checkbox"
                          id={`genere${index}`}
                          checked={selectedGeners?.includes(genere?.id) || false}
                          onChange={() => {}}
                          style={{ width: "1.5em", height: "1.5em", marginRight: "1em", marginLeft: "-15px" }}
                        />
                        <label className="form-check-label ml-2 fs-6 rounded-3">
                          {genere?.title}
                          <span>
                            {" "}
                            (
                            {listCount && listCount.data && listCount.data.genre_counts
                              ? Object.entries(listCount.data.genre_counts).map(([generecount, count], index) => (
                                  <span key={`genre-${index}`}>{generecount === genere?.title ? count : ""}</span>
                                ))
                              : ""}
                            )
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Filter;
